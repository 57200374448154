export const initialState = {
  user: {
    id: "",
    user_name: "",
  },
  userListing: {
    items: [],
  },
  itemsToBeCollected: [],
};
